import {
  convertValueToUTCDate,
  dateToDayEndUTC,
  dateToDayStartUTC,
  dateToUTC,
  format as formatCoreUI,
  getTimestampInMs as getTimestampInMsCoreUI,
  localizeDate,
  timezone,
} from '@core-ui/dates';
import {
  addMonths as addMonthsFNS,
  eachMonthOfInterval as eachMonthOfIntervalFNS,
  endOfDay as endOfDayFNS,
  endOfMonth as endOfMonthFNS,
  endOfToday as endOfTodayFNS,
  endOfYear as endOfYearFNS,
  fromUnixTime as fromUnixTimeFNS,
  getMonth as getMonthFNS,
  getUnixTime as getUnixTimeFNS,
  getYear as getYearFNS,
  parseISO as parseISOFNS,
  startOfDay as startOfDayFNS,
  startOfMonth as startOfMonthFNS,
  startOfYear as startOfYearFNS,
  sub as subFNS,
} from 'date-fns';

export const getEndOfTodayInUnix = () => Math.floor(localizeDate(endOfTodayFNS(), timezone).getTime() / 1000);
export const getMonthAgoInUnix = () =>
  Math.floor(localizeDate(sub(endOfToday(), { months: 1 }), timezone).getTime() / 1000);
export const getStartOfCurrentYearInUnix = () => dateToDayStartUTC(startOfYear(dateToDayStartUTC(new Date()) * 1000));
export const getEndOfCurrentYearInUnix = () =>
  Math.floor(dateToDayStartUTC(endOfYearFNS(dateToDayStartUTC(new Date()) * 1000)));

export const formatTime = formatCoreUI;
export const getTimestampInMs = getTimestampInMsCoreUI;
export const startOfYear = startOfYearFNS;
export const startOfMonth = startOfMonthFNS;
export const startOfDay = startOfDayFNS;
export const endOfDay = endOfDayFNS;
export const endOfToday = endOfTodayFNS;
export const endOfMonth = endOfMonthFNS;
export const endOfYear = endOfYearFNS;
export const getUnixTime = getUnixTimeFNS;
export const fromUnixTime = fromUnixTimeFNS;
export const addMonths = addMonthsFNS;
export const sub = subFNS;
export const parseISO = parseISOFNS;
export const getMonth = getMonthFNS;
export const getYear = getYearFNS;
export const eachMonthOfInterval = eachMonthOfIntervalFNS;

export { dateToUTC, convertValueToUTCDate, dateToDayStartUTC, dateToDayEndUTC };
