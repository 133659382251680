/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Period {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}
