export const GREY_PALETTE = {
  160: '#18181A',
  60: '#E3E3EB',
};

export const COLOURS = {
  LINK: '#0725BB', // как в common.css ссылка#
  LINK_HOVER: '#0C32F1', // как в common.css ссылка#
  ORANGE: '#ff8a2f',
  PRIMARY: '#2a4aee',
  SECONDARY: '#19CD4B',
  ERROR: '#EF475D',
  WARNING: '#f6da6e',
  DARK: '#6c757d',
  DEFAULT: '#e2e2e2',
  DISABLED: '#ddd', // это для текста
  DISABLED_BACKGROUND: '#f5f5f5',
  BORDER: '#d4d6e1',
  BORDER_TABLE: '#EDEDEF',
  PLACEHOLDER: 'rgba(153, 153, 153, 0.8)',
  LIGHT: '#fafbfe',
  PINK: '#f198ca',
  TEXT_LIGHT: '#adafba',
  BTN_PRIMARY: '#2A4AEE',
  BTN_DISABLED_BACKGROUND: '#d1d8fb',
  // ------------------------- OCEAN
  TEXT_PRIMARY: '#232325',

  SUBTITLE2: '#A2A2A6',

  SURFACE_PRIMARY: '#F5F5FA',
  SURFACE_SECONDARY: '#EBEBF2',
};

export const TOP_MENU_HEIGHT = 48;

export const GRID_COLUMN_BASE = 48;
