/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RequestTypeEnum {
  DEFAULT = 'default',
  EMERGENCY = 'emergency',
  SALARY = 'salary',
  CASH = 'cash',
  RECURRENT = 'recurrent',
  WALLET = 'wallet',
}
