export const POPULAR_CURRENCIES = [
  { value: 101, label: 'USD' },
  { value: 103, label: 'EUR' },
];

export enum EMPLOYEE_POSITION_ID_ENUM {
  CAPITAN = 1,
  FIRST_MATE = 2,
  SECOND_MATE = 3,
  BOATSWAIN = 4,
  CHIEF_ENGINEER = 5,
  COOK = 6,
  SECOND_ENGINEER = 7,
  SUPER_INTENDANT = 8,
  PURSER = 9,
  PURCHASING_MANAGER = 10,
  ACCOUNTANT = 11,
}

export enum EMPLOYEE_POSITION_ENUM {
  CAPITAN = 'Capitan',
  FIRST_MATE = 'First mate',
  SECOND_MATE = 'Second mate',
  BOATSWAIN = 'Boatswain',
  CHIEF_ENGINEER = 'Chief Engineer',
  COOK = 'Cook',
  SECOND_ENGINEER = 'Second engineer',
  SUPER_INTENDANT = 'Super intendant',
  PURSER = 'Purser',
  PURCHASING_MANAGER = 'Purchasing manager',
  ACCOUNTANT = 'Accountant',
}
