import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import React, { FC, useEffect, useRef, useState } from 'react';
import useStyles from './styles';

enum ORIENTATION {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

interface IProps {
  src?: string;
  zoom: number;
  rotate: number;
}

const ImageItem: FC<IProps> = (props) => {
  const { src, rotate, zoom } = props;
  const styles = useStyles({ rotate });

  const [sizeImage, setSizeImage] = useState<number | undefined>();
  const [orientation, setOrientation] = useState<Nullable<ORIENTATION>>(null);

  const imageRef = useRef<HTMLImageElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageSize = useRef<{ width: number; height: number }>({ width: 0, height: 0 });

  const setImageSizeWithZoom = () => {
    const width = imageSize.current.width + imageSize.current.width * (zoom * 0.1);
    const height = imageSize.current.height + imageSize.current.height * (zoom * 0.1);
    const size = Math.min(height, width);

    setSizeImage(size === 0 ? undefined : size);
  };

  const onLoad = () => {
    if (zoom === 0) {
      const width = imageRef.current!.naturalWidth;
      const height = imageRef.current!.naturalHeight;

      setOrientation(width / height <= 1 ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE);

      imageSize.current.width = wrapperRef.current!.clientWidth;
      imageSize.current.height = wrapperRef.current!.clientHeight;

      const size = Math.min(imageSize.current.width, imageSize.current.height);

      setSizeImage(size === 0 ? undefined : size);
    } else {
      setImageSizeWithZoom();
    }
  };

  useEffect(() => {
    setOrientation(orientation === ORIENTATION.LANDSCAPE ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE);
  }, [rotate]);

  useEffect(() => {
    setImageSizeWithZoom();
  }, [zoom]);

  return (
    <Grid item container alignItems="center">
      <Grid item container height="100%" flexWrap="nowrap" sx={styles.pages} ref={wrapperRef}>
        <img
          draggable={false}
          onLoad={onLoad}
          ref={imageRef}
          src={src}
          alt="Document"
          style={{ ...styles.image, width: sizeImage, height: sizeImage }}
        />
      </Grid>
    </Grid>
  );
};

export default ImageItem;
