/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentType {
  PRE_PAYMENT = 'pre_payment',
  POST_PAYMENT = 'post_payment',
}
