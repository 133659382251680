/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum QtyTypeEnum {
  GR = 'gr',
  KG = 'kg',
  PKG = 'pkg',
  PUNNET = 'punnet',
  L = 'l',
  GAL = 'gal',
  M2 = 'm2',
  CM2 = 'cm2',
  MM2 = 'mm2',
  KGM3 = 'kgm3',
  GM3 = 'gm3',
  LBCU = 'lbcu',
  KWH = 'kwh',
  WH = 'wh',
  M = 'm',
  CM = 'cm',
  MM = 'mm',
  FT = 'ft',
  IN = 'in',
  G = 'g',
  MG = 'mg',
  LB = 'lb',
  OZ = 'oz',
  M3 = 'm3',
  CM3 = 'cm3',
  MM3 = 'mm3',
  CL = 'cl',
  ML = 'ml',
  CUFT = 'cuft',
  CUIN = 'cuin',
  USGAL = 'usgal',
  USFLOZ = 'usfloz',
  PCS = 'pcs',
}
