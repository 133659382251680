import State from 'src/app/types/state';
import { boatDictSelector } from 'src/dictionary/selector';
import { createSelector } from 'reselect';

const getBoatIdFromProps = (_: State, props: { boatId: string }) => +props.boatId;

const getBoatHashLinkSelector = createSelector(
  [getBoatIdFromProps, boatDictSelector],
  (boatId, boats) => (boats.find((boat) => boat.id === boatId) || {}).map_id
);

export default createSelector([getBoatHashLinkSelector], (mapId) => ({
  mapId,
}));
