import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { INITIAL_ZOOM, MAX_ZOOM } from '../ImagesCarousel';
import useStyles from '../styles';

enum ORIENTATION {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

interface IProps {
  src: string;
  zoom: number;
  rotate: number;
  setZoom?: (value: number) => void;
}

const ImageItem: React.FC<IProps> = (props) => {
  const { src, rotate, zoom, setZoom } = props;
  const styles = useStyles({ rotate });

  const [sizeImage, setSizeImage] = useState<number | undefined>();
  const [orientation, setOrientation] = useState<Nullable<ORIENTATION>>(null);
  const [isDrag, setDrag] = useState(false);
  const [hasMove, setMove] = useState(false);
  const [position, setPosition] = useState({ scrollTop: 0, scrollLeft: 0, x: 0, y: 0 });

  const imageRef = useRef<HTMLImageElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageSize = useRef<{ width: number; height: number }>({ width: 0, height: 0 });

  const isMinZoom = zoom <= INITIAL_ZOOM;
  const isMaxZoom = zoom === MAX_ZOOM;

  const imageStyles = {
    ...styles.image,
    ...(setZoom && isMaxZoom && styles.imageZoomOut),
    ...(setZoom && isMinZoom && styles.imageZoomIn),
    ...(isDrag && !isMinZoom && styles.imageDrag),
  };

  const onLoad = () => {
    const width = imageRef.current!.naturalWidth;
    const height = imageRef.current!.naturalHeight;

    setOrientation(width / height <= 1 ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE);

    imageSize.current.width = wrapperRef.current!.clientWidth;
    imageSize.current.height = wrapperRef.current!.clientHeight;

    const size = Math.max(imageSize.current.width, imageSize.current.height);

    setSizeImage(size === 0 ? undefined : size);
  };

  const onClick = (e: MouseEvent) => {
    const imageRect = imageRef.current!.getBoundingClientRect();
    const coordX = e.pageX - imageRect.left;
    const coordY = e.pageY - imageRect.top;

    if (setZoom && !hasMove) {
      setZoom(zoom !== MAX_ZOOM ? MAX_ZOOM : INITIAL_ZOOM);

      setTimeout(() => {
        if (wrapperRef.current) {
          wrapperRef.current.scrollLeft = coordX;
          wrapperRef.current.scrollTop = coordY;
        }
      }, 0);
    }
  };

  const onMouseDown = (e: MouseEvent) => {
    setDrag(true);
    setPosition({
      scrollLeft: wrapperRef.current!.scrollLeft,
      scrollTop: wrapperRef.current!.scrollTop,
      x: e.clientX,
      y: e.clientY,
    });
  };

  const onMouseMove = (e: MouseEvent) => {
    if (!hasMove) {
      setMove(true);
    }
    const deltaX = e.clientX - position.x;
    const deltaY = e.clientY - position.y;

    wrapperRef.current!.scrollLeft = position.scrollLeft - deltaX;
    wrapperRef.current!.scrollTop = position.scrollTop - deltaY;
  };

  const onMouseUp = () => {
    setDrag(false);

    setTimeout(() => {
      setMove(false);
    }, 0);
  };

  useEffect(() => {
    setOrientation(orientation === ORIENTATION.LANDSCAPE ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE);
  }, [rotate]);

  useEffect(() => {
    const width = imageSize.current.width + imageSize.current.width * (zoom * 0.1);
    const height = imageSize.current.height + imageSize.current.height * (zoom * 0.1);
    const size = Math.max(height, width);

    setSizeImage(size === 0 ? undefined : size);
  }, [zoom]);

  return (
    <Grid
      container
      flexWrap="nowrap"
      sx={styles.pages}
      ref={wrapperRef}
      onMouseDown={!isMinZoom ? onMouseDown : undefined}
      onMouseMove={isDrag ? onMouseMove : undefined}
      onMouseUp={!isMinZoom ? onMouseUp : undefined}
    >
      <img
        draggable={false}
        onLoad={onLoad}
        onClick={setZoom ? onClick : undefined}
        ref={imageRef}
        src={src}
        alt="Document page"
        style={{ ...imageStyles, width: sizeImage, height: sizeImage }}
      />
    </Grid>
  );
};

export default ImageItem;
