/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FileTypeEnum {
  UNSUPPORTED = 'unsupported',
  BINARY = 'binary',
  FILE = 'file',
  IMAGE = 'image',
  PDF = 'pdf',
  TIFF = 'tiff',
  ZIP = 'zip',
  DIRECTORY = 'directory',
}
