import { COLOURS } from '@core-ui/styles';
import { ApproveStatusEnum } from 'src/generated';

export const colorsByStatus: Record<ApproveStatusEnum, Record<'text' | 'indicator', string>> = {
  [ApproveStatusEnum.AWAITING]: {
    text: COLOURS.HUE_COCONUT.HUE_100,
    indicator: COLOURS.HUE_COCONUT.HUE_128,
  },
  [ApproveStatusEnum.APPROVED]: {
    text: COLOURS.HUE_AVOCADO.HUE_55,
    indicator: COLOURS.HUE_AVOCADO.HUE_120,
  },
  [ApproveStatusEnum.DECLINED]: {
    text: COLOURS.HUE_STRAWBERRY.HUE_55,
    indicator: COLOURS.HUE_STRAWBERRY.HUE_120,
  },
};

export const textByStatus: Record<ApproveStatusEnum, string> = {
  [ApproveStatusEnum.AWAITING]: 'label.awaiting',
  [ApproveStatusEnum.APPROVED]: 'label.approved',
  [ApproveStatusEnum.DECLINED]: 'label.declined',
};
