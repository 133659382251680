import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IFileState {
  [prop: string]: {
    loadingFiles: actions.ILoadFile[];
  };
}

const defaultState: IFileState = {};

export default handleActions<typeof defaultState, any>({}, defaultState);
