/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ApproveStatusEnum {
  AWAITING = 'awaiting',
  APPROVED = 'approved',
  DECLINED = 'declined',
}
