/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DocTypeEnum {
  DEFAULT = 'default',
  QUOTE = 'quote',
  PRE_PAYMENT_INVOICE = 'pre_payment_invoice',
  POST_PAYMENT_INVOICE = 'post_payment_invoice',
  RECEIPT = 'receipt',
}
