import { MIN_ROTATE } from '../consts';

export default (props: { rotate: number } = { rotate: MIN_ROTATE }) => ({
  image: {
    margin: 'auto',
    maxWidth: 'none',
    maxHeight: 'none',
    userSelect: 'none' as const,
    transform: `rotate(${props.rotate}deg)`,
    objectFit: 'contain' as const,
  },
  imageZoomIn: {
    cursor: 'zoom-in',
  },
  imageZoomOut: {
    cursor: 'zoom-out',
  },
  imageDrag: {
    cursor: 'grab',
  },
  pages: {
    position: 'relative',
    height: '100%',
    overflow: 'scroll',

    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
    '&::-webkit-scrollbar-track': {
      height: 0,
      width: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      height: 0,
      width: 0,
    },
  },
});
