import makeStyles from '@mui/styles/makeStyles';
import { StatusInfoColors } from './types';

// TODO: все эти цвета нужно заменить на цвета из корки
const successStyle = {
  backgroundColor: '#CEF0F5',
};
const infoStyle = {
  backgroundColor: '#2183F3',
};
const dangerStyle = {
  backgroundColor: '#FFC5C5',
};
const pendingStyle = {
  backgroundColor: '#FFE3DB',
};
const primaryStyle = {
  backgroundColor: '#FFE3DB',
};
const draftStyle = {
  backgroundColor: '#DFD6EF',
};
const approvalStyle = {
  backgroundColor: '#FFE7B9',
};
const approvedStyle = {
  backgroundColor: '#D7EBEE',
};
const deliveredStyle = {
  backgroundColor: '#B0D3FA',
};
const doneStyle = {
  backgroundColor: '#C2F8E0',
};
const digitizedStyle = {
  backgroundColor: '#D6D3E2',
};

export default makeStyles({
  root: {
    whiteSpace: 'nowrap',
    padding: '2px 4px',
    borderRadius: 4,
    textTransform: 'uppercase',
  },
  [StatusInfoColors.INFO]: infoStyle,
  [StatusInfoColors.SUCCESS]: successStyle,
  [StatusInfoColors.DANGER]: dangerStyle,
  [StatusInfoColors.PRIMARY]: primaryStyle,
  [StatusInfoColors.PENDING]: pendingStyle,
  [StatusInfoColors.DRAFT]: draftStyle,
  [StatusInfoColors.APPROVAL]: approvalStyle,
  [StatusInfoColors.APPROVED]: approvedStyle,
  [StatusInfoColors.DELIVERED]: deliveredStyle,
  [StatusInfoColors.DONE]: doneStyle,
  [StatusInfoColors.DIGITIZED]: digitizedStyle,
});
