import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import React, { FC, forwardRef } from 'react';

interface IProps {
  style?: SxProps<Theme>;

  [prop: string]: any;
}

const SidePanel: FC = forwardRef<any, IProps>((props, ref) => {
  const { children, style, ...rest } = props;

  return (
    <Grid item container ref={ref} height="100%" width="100%" sx={style} {...rest}>
      {children}
    </Grid>
  );
});

export default SidePanel;
