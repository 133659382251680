import TextField, { TextFieldProps } from '@core-ui/text_field';
import { onChangeWithoutEvent } from '@core-ui/text_field/types';
import { Nullable } from '@core-ui/types';
import { mergeRefs } from 'src/app/utils/app';
import { AnyMaskedOptions, InputMask } from 'imask';
import React, { useEffect, useRef } from 'react';
import { DEFAULT_NUMBER_MASK } from './masks';

export const parseNumber = (value: number | string) => {
  if (value) {
    const stringValue = String(value);
    const lastIndex = stringValue.length - 1;
    const hasLeadingZero = (stringValue.includes(',') || stringValue.includes('.')) && stringValue[lastIndex] === '0';

    if (stringValue[lastIndex] === ',' || stringValue[lastIndex] === '.' || hasLeadingZero) {
      return stringValue;
    }

    return typeof value === 'number' ? value : parseFloat(value);
  }

  return value;
};

const getValue = (v: any) => (typeof v === 'number' ? `${v === 0 ? '0' : v}` : typeof v === 'string' ? v : '');

interface IProps extends Omit<TextFieldProps, 'onChange'> {
  onChange?: onChangeWithoutEvent;
  mask?: AnyMaskedOptions;
  maxValue?: number;
  minValue?: number;
}

const TextFieldMasked = (props: IProps) => {
  const { mask = DEFAULT_NUMBER_MASK, onChange, value, maxValue, minValue, inputRef, ...rest } = props;

  const ref = useRef();
  const maskRef = useRef<Nullable<InputMask<typeof mask>>>(null);

  useEffect(() => {
    const maskObj = new InputMask(ref.current!, mask);
    maskObj.value = getValue(value);
    maskRef.current = maskObj;

    return () => {
      maskObj.destroy();
    };
  }, []);

  useEffect(() => {
    const maskObj = maskRef.current;

    if (!maskObj) {
      return () => {};
    }

    const onAccept = (inputEvent?: InputEvent) => {
      if (onChange) {
        if (mask.mask === Number) {
          if (maskObj.value.length === 0) {
            maskObj.value = '';

            // @ts-ignore
            onChange(undefined);

            return;
          }

          let maskedValue = maskObj.value;
          if (maxValue || maxValue === 0) {
            const newNumber = Number(maskObj.value);
            const newValue = maxValue < newNumber ? maxValue : newNumber;
            const v = Number.isNaN(newNumber) || `${maskObj.value}`.endsWith('.0') ? maskObj.value : getValue(newValue);
            // @ts-ignore radix не существует в типе
            if (!Number.isNaN(newNumber) && inputEvent?.data !== mask.radix) {
              maskObj.value = v;
            }
            maskedValue = v;
          }

          if (minValue || minValue === 0) {
            const newNumber = Number(maskObj.value);
            const newValue = minValue > newNumber ? minValue : newNumber;
            const v = Number.isNaN(newNumber) || `${maskObj.value}`.endsWith('.0') ? maskObj.value : getValue(newValue);
            // @ts-ignore radix не существует в типе
            if (!Number.isNaN(newNumber) && inputEvent?.data !== mask.radix) {
              maskObj.value = v;
            }
            maskedValue = v;
          }

          onChange(maskedValue);
        } else {
          onChange(maskObj.value);
        }
      }
    };

    maskObj.on('accept', onAccept);

    return () => {
      maskObj.off('accept', onAccept);
    };
  }, [onChange, maxValue, minValue]);

  useEffect(() => {
    const maskObj = maskRef.current;

    if (maskObj) {
      maskObj.updateOptions(mask);
    }
  }, [mask]);

  useEffect(() => {
    const maskObj = maskRef.current;

    if (maskObj) {
      maskObj.value = getValue(value);
    }
  }, [value]);

  return <TextField {...rest} inputRef={mergeRefs(ref, inputRef)} />;
};

export default TextFieldMasked;
