import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PLACEHOLDER_POSITION_ADJUSTMENT } from 'src/pages/backoffice/consts';
import { FileText } from '@phosphor-icons/react';
import React, { CSSProperties, FC } from 'react';
import { FormattedMessage } from 'react-intl';

const containerStyle: CSSProperties = {
  marginTop: PLACEHOLDER_POSITION_ADJUSTMENT,
};

const NoImagePlaceholder: FC = () => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={containerStyle}>
      <Grid item marginBottom={4}>
        <FileText size={52} />
      </Grid>

      <Grid item marginBottom={2}>
        <Typography variant="h5">
          <FormattedMessage id="label.no_preview_available" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoImagePlaceholder;
