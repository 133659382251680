import { Nullable } from '@core-ui/types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import React, { FC, MutableRefObject, ReactNode } from 'react';

export interface IMenuOption {
  title: ReactNode;
  onClick: () => void;
  visible?: boolean;
  icon?: ReactNode;
  textColor?: string;
  dataTestId?: string;
}

interface IProps {
  options: IMenuOption[];
  open: boolean;
  anchorRef: MutableRefObject<Nullable<HTMLDivElement>>;
  onClose: (event: TouchEvent | MouseEvent | React.MouseEvent<HTMLLIElement>) => void;
  disablePortal?: boolean;
  zIndex?: number;
}

const Menu: FC<IProps> = (props) => {
  const { options, open, anchorRef, onClose, disablePortal = false, zIndex = 2 } = props;

  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => (option: IMenuOption) => {
    option.onClick();
    onClose(e);
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal={disablePortal}
      style={{ zIndex }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            boxShadow: '0px 12px 32px -2px rgba(0, 0, 0, 0.12)',
            marginTop: '8px',
            transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
          }}
        >
          <Paper elevation={0}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList>
                {options.reduce<JSX.Element[]>((acc, option, idx) => {
                  if (!option.visible) {
                    return acc;
                  }

                  const newOption = (
                    <MenuItem key={idx} onClick={(e) => handleClick(e)(option)}>
                      <Grid container gap={2} justifyContent="flex-start">
                        {option.icon && (
                          <Grid item>
                            <SvgIcon>{option.icon}</SvgIcon>
                          </Grid>
                        )}

                        <Typography variant="body1" color={option.textColor}>
                          {option.title}
                        </Typography>
                      </Grid>
                    </MenuItem>
                  );

                  return [...acc, newOption];
                }, [])}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default Menu;
