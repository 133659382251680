/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EmployeeTypeEnum {
  CREW = 'crew',
  OFFICE = 'office',
}
