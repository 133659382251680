/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RequestSortingColumns {
  NAME = 'name',
  REQUEST_TYPE_ID = 'request_type_id',
  REQUEST_STATUS_ID = 'request_status_id',
  EMPLOYEE_ID = 'employee_id',
  CREATED_AT = 'created_at',
  EXPIRED_AT = 'expired_at',
  AMOUNT = 'amount',
}
