import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { Check, Hourglass, Prohibit } from '@phosphor-icons/react';
import React from 'react';
import useStyles from '../styles';

export const ApprovedBall = () => {
  const styles = useStyles();

  return (
    <Grid item container justifyContent="center" alignItems="center" sx={styles.approved}>
      <SvgIcon fontSize="small" htmlColor={COLOURS.HUE_AVOCADO.HUE_100}>
        <Check />
      </SvgIcon>
    </Grid>
  );
};

export const DeclinedBall = () => {
  const styles = useStyles();

  return (
    <Grid item container justifyContent="center" alignItems="center" sx={styles.declined}>
      <SvgIcon fontSize="small" htmlColor={COLOURS.HUE_STRAWBERRY.HUE_100}>
        <Prohibit />
      </SvgIcon>
    </Grid>
  );
};

export const AwaitingBall = () => {
  const styles = useStyles();

  return (
    <Grid item container justifyContent="center" alignItems="center" sx={styles.waiting}>
      <SvgIcon fontSize="small" htmlColor={COLOURS.HUE_COCONUT.HUE_100}>
        <Hourglass />
      </SvgIcon>
    </Grid>
  );
};
