import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { classNameForOverlay } from './components/ImageItemOverlay';
import { MIN_ROTATE } from './ImagesCarousel';

const CONTROLS_HEIGHT = 64;
const GAP_LIST = 30;

interface IStyleProps {
  rotate?: number;
  showControls?: boolean;
}

export default (props: IStyleProps = { rotate: MIN_ROTATE }): Record<string, SxProps<Theme>> => ({
  list: {
    padding: '30px 70px',
    gap: `${GAP_LIST}px`,
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute !important',
    right: 16,
    top: 16,
  },
  pagesWrapperOverlay: {
    opacity: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 100%)',
    transition: 'opacity 0.2s',
  },
  pagesWrapper: {
    position: 'relative',
    backgroundColor: COLOURS.TEXT_CONTROL.MAIN,
    height: '100%',
    maxHeight: props.showControls ? `calc(100% - ${CONTROLS_HEIGHT + GAP_LIST}px)` : '100%',

    [`&:hover .${classNameForOverlay}`]: {
      opacity: 1,
    },
  },
  pages: {
    position: 'relative',
    height: '100%',
    overflow: 'scroll',

    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
    '&::-webkit-scrollbar-track': {
      height: 0,
      width: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      height: 0,
      width: 0,
    },
  },
  pagePagination: {
    width: 28,
    height: 48,
    opacity: 0.64,
    cursor: 'pointer',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    transition: 'width .2s, opacity .2s',
  },
  image: {
    margin: 'auto',
    maxWidth: 'none',
    maxHeight: 'none',
    userSelect: 'none' as const,
    transform: `rotate(${props.rotate}deg)`,
    objectFit: 'contain' as const,
  },
  imageZoomIn: {
    cursor: 'zoom-in',
  },
  imageZoomOut: {
    cursor: 'zoom-out',
  },
  imageDrag: {
    cursor: 'grab',
  },
  buttonPrev: {
    position: 'absolute !important',
    top: '50%',
    right: '100%',
    transform: 'translate(-16px, -50%)',
  },
  buttonNext: {
    position: 'absolute !important',
    top: '50%',
    left: '100%',
    transform: 'translate(16px, -50%)',
  },
  controls: {
    height: CONTROLS_HEIGHT,
    padding: 2,
    backgroundColor: COLOURS.TEXT_CONTROL.MAIN,
  },
  controlComments: {
    marginLeft: 10,
  },
});
