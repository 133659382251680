import cookieManager from 'js-cookie';

const config = {};

export const oceanTokenName = 'ocean';
export const chatTokenName = 'chat_ocean';

export function getToken(name: string) {
  return cookieManager.get(name);
}

export const setToken = (name: string, tokenValue: string) => {
  cookieManager.set(name, tokenValue, config);
};

export const removeTokens = (settings: object = config) => {
  cookieManager.remove(oceanTokenName, settings);
  cookieManager.remove(chatTokenName, settings);
};
