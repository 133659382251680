import { COLOURS } from '@core-ui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import makeStyles from '@mui/styles/makeStyles';

export const useSXStyles = () => ({
  autocomplete: (theme: Theme) => ({
    minWidth: 205,

    '.MuiButtonBase-root': {
      color: COLOURS.WHITE,
    },

    '.MuiTextField-root > .MuiOutlinedInput-root > .MuiOutlinedInput-input': {
      ...theme.typography.body2,
      caretColor: COLOURS.WHITE,
      color: COLOURS.WHITE,
      fontWeight: 600,
    },

    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '.MuiTextField-root > .MuiOutlinedInput-root': {
      backgroundColor: 'transparent',
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
      },
    },

    '.MuiTextField-root > .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input': {
      color: COLOURS.HUE_COCONUT.HUE_100,
      WebkitTextFillColor: COLOURS.HUE_COCONUT.HUE_100,
    },

    '.Mui-disabled': {
      '& path': {
        stroke: COLOURS.HUE_COCONUT.HUE_100,
      },
    },
  }),
});

export default makeStyles(
  {
    body: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    selection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: COLOURS.HUE_COCONUT.HUE_153,
      borderRadius: 4,
      paddingLeft: 8,
      marginLeft: 24,
    },

    aircraftSelection: {
      margin: '0 20px',
    },
  },
  { name: 'AircraftSelection' }
);
