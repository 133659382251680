import { changePropState } from '@core-ui/reducers_handlers';
import { buildURL } from '@core-ui/url';
import { getAppUserSelector, vehicleIdSelector } from 'src/app/selectors';
import { ICurrencyDict, IDictState } from 'src/dictionary/reducer';
import {
  BoatWithConfigSchemaModel,
  ClientCostArticleInfo,
  EmployeeSchema,
  RequestShortInfoSchema,
  VendorsInfo,
} from 'src/generated';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { GET } from '../api/oceanApi';
import { responseError } from '../app/sagas';
import * as actions from './actions';
import BACKEND_ROUTES from './backendRoutes';

const currenciesMapModifier = (data: any[]) =>
  data.reduce<Record<number, ICurrencyDict>>(
    (acc, currency) => ({
      ...acc,
      [currency.id]: currency,
    }),
    {}
  );

export const action = changePropState(actions.reducerName);

const getDictionary = (url: string, key: keyof IDictState) => {
  return function* () {
    try {
      const response: Array<unknown> = yield call(GET, url);
      const data = Array.isArray(response) ? response : [];

      const effects = [action([key], data)];

      if (key === 'currencies') {
        effects.push(action(['currencyMap'], currenciesMapModifier(data)));
      }

      yield all(effects.map((effect) => put(effect)));
    } catch (e) {
      yield put(action([key], []));
      yield call(responseError, e);
    }
  };
};

const getCurrencyDict = getDictionary(BACKEND_ROUTES.GET_CURRENCIES, 'currencies');
const getDepartmentDict = getDictionary(BACKEND_ROUTES.GET_DEPARTMENTS, 'departments');
const getEmployeesDict = getDictionary(BACKEND_ROUTES.GET_EMPLOYEES, 'employees');
const getCostCentersDict = getDictionary(BACKEND_ROUTES.GET_COST_CENTERS, 'costCenters');
const getCostArticlesDict = getDictionary(BACKEND_ROUTES.GET_COST_ARTICLES, 'costArticles');

function* getClientCostArticleDict() {
  try {
    const boatId: number = yield select(vehicleIdSelector);
    const url = buildURL(BACKEND_ROUTES.GET_CLIENT_COST_ARTICLES, { boat_id: boatId });

    const response: ClientCostArticleInfo[] = yield call(GET, url);
    const data = Array.isArray(response) ? response : [];

    yield put(actions.setClientCostArticleDict(data));
  } catch (e) {
    yield put(actions.setClientCostArticleDict([]));
    yield call(responseError, e);
  }
}

// this saga cannot be replaced with getDictionary because we need to wait for take(setBoatsDict) in app/sagas.ts
function* getBoatsDict() {
  try {
    const response: BoatWithConfigSchemaModel[] = yield call(GET, BACKEND_ROUTES.GET_BOATS);
    const data = Array.isArray(response) ? response : [];

    yield put(actions.setBoatDict(data));
  } catch (e) {
    yield put(actions.setBoatDict([]));
    yield call(responseError, e);
  }
}

// todo: this saga cannot be replaced with getDictionary because it uses boatId, we need to move it somewhere else
function* getVendorsDict() {
  try {
    const boatId: number = yield select(vehicleIdSelector);

    const url = buildURL(BACKEND_ROUTES.GET_VENDORS, {
      boat_id: boatId,
    });

    const response: VendorsInfo[] = yield call(GET, url);
    const data = Array.isArray(response) ? response : [];

    yield put(actions.setVendorDict(data));
  } catch (e) {
    yield put(actions.setVendorDict([]));
    yield call(responseError, e);
  }
}

// todo: this saga cannot be replaced with getDictionary because it uses boatId, we need to move it somewhere else
function* getRequestsDict() {
  try {
    const boatId: number = yield select(vehicleIdSelector);

    const response: RequestShortInfoSchema[] = yield call(GET, `/boat/${boatId}/${BACKEND_ROUTES.GET_REQUESTS}`);
    const data = Array.isArray(response) ? response : [];

    yield put(actions.setRequestDict(data));
  } catch (e) {
    yield put(actions.setRequestDict([]));
    yield call(responseError, e);
  }
}

// todo: this saga cannot be replaced with getDictionary because it uses user, we need to move it somewhere else
function* getUserNotificationsDict() {
  try {
    const user: EmployeeSchema = yield select(getAppUserSelector);

    // TODO: в свагере нет модели для ответа, добавить сюда как появится
    const response: [] = yield call(GET, `/employees/${user.id}/notifications`);
    const data = Array.isArray(response) ? response : [];

    yield put(actions.setUserNotificationDict(data));
  } catch (e) {
    yield put(actions.setUserNotificationDict([]));
    yield call(responseError, e);
  }
}

export default function* dictionarySagas() {
  yield all([
    takeEvery(actions.getBoatList, getBoatsDict),
    takeEvery(actions.getCurrencyDict, getCurrencyDict),
    takeEvery(actions.getDepartmentDict, getDepartmentDict),
    takeEvery(actions.getEmployeeDict, getEmployeesDict),
    takeEvery(actions.getCostCenterDict, getCostCentersDict),
    takeEvery(actions.getCostArticleDict, getCostArticlesDict),
    takeEvery(actions.getClientCostArticleDict, getClientCostArticleDict),
    takeEvery(actions.getVendorDict, getVendorsDict),
    takeEvery(actions.getRequestDict, getRequestsDict),
    takeEvery(actions.getUserNotificationDict, getUserNotificationsDict),
  ]);
}
