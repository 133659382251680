/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CalculatedRequestStatusEnum {
  DRAFT_REQUEST = 'Draft request',
  REQUEST_AWAITING_APPROVAL = 'Request awaiting approval',
  REQUEST_APPROVED = 'Request approved',
  COLLECTING_QUOTES = 'Collecting quotes',
  REQUEST_DECLINED = 'Request declined',
  QUOTE_S_BEING_DIGITIZED = 'Quote(s) being digitized',
  QUOTE_AWAITING_APPROVAL = 'Quote awaiting approval',
  QUOTE_APPROVED = 'Quote approved',
  QUOTE_DECLINED = 'Quote declined',
  AWAITING_INVOICE = 'Awaiting invoice',
  INVOICE_S_BEING_DIGITIZED = 'Invoice(s) being digitized',
  INVOICE_AWAITING_APPROVAL = 'Invoice awaiting approval',
  INVOICE_DECLINED = 'Invoice declined',
  AWAITING_PAYMENT = 'Awaiting payment',
  PAID = 'Paid',
  DONE = 'Done',
  PAYMENT_PENDING_APPROVAL = 'Payment pending approval',
  PAID_DELIVERED = 'Paid & delivered',
  QUOTE_HAS_ERROR = 'Quote has error',
  INVOICE_HAS_ERROR = 'Invoice has error',
  UNKNOWN = 'Unknown',
  DELIVERED = 'Delivered',
}
