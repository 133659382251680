import Typography from '@mui/material/Typography';
import { getDepartmentById } from 'src/dictionary/selector';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useMemo } from 'react';

interface IProps {
  departmentId: number;
}

const Department: React.FC<IProps> = (props) => {
  const { departmentId } = props;

  const memoizedSelector = useMemo(() => getDepartmentById, []);
  const department = useAppSelector((state) => memoizedSelector(state, { id: departmentId }));

  if (!department) {
    return null;
  }

  return <Typography variant="body1">{department.name}</Typography>;
};

export default Department;
