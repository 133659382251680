import { ROUTES } from 'src/app/consts/routes';
import { ANALYTICS_INNER_TABS } from 'src/pages/analytics/AnalyticsInner/consts';
import { ANALYTICS_TABS } from 'src/pages/analytics/consts';
import { ANALYTICS_PATHS } from 'src/pages/analytics/routes';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { FINANCES_TABS } from 'src/pages/finances/consts';

/*
 * List of all allowed paths without boatId and entities id, i.e. /backoffice/wallets/69 - 69 is a wallet entity id.
 * These paths are used to redirect user to the same page when he changes the boat or to the parent page if
 * the boat was changed when user was on the page with an entity id, because page with this entity id
 * won't exist in the new boat.
 * In other words, when a boat is changed on /backoffice/wallets/69 user will be redirected to /backoffice/wallets
 */
export const allowedPaths = [
  `${ROUTES.ANALYTICS}/${ANALYTICS_TABS.ANALYTICS}/${ANALYTICS_INNER_TABS.EXPENSES}`,
  `${ROUTES.ANALYTICS}/${ANALYTICS_TABS.ANALYTICS}/${ANALYTICS_INNER_TABS.PLAN_FACT}`,
  `${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.INBOX}`,
  `${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.WALLETS}`,
  `${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.CONTRACTS}`,
  `${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}`,
  `${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`,
  `${ROUTES.CHATS}`,
  `${ROUTES.DOCUMENTS}`,
  `${ROUTES.FINANCES}/${FINANCES_TABS.BUDGET}`,
  `${ROUTES.SETTINGS}`,
];
