/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RequestSubStatusEnum {
  PAID = 'paid',
  DISPUTE = 'dispute',
  DELIVERED = 'delivered',
  ERROR = 'error',
  ARCHIVE = 'archive',
}
