import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import cn from 'classnames';
import { X } from '@phosphor-icons/react';
import React, { ReactNode, useState } from 'react';
import Carousel from './components/Carousel';
import Controls from './components/Controls';
import useStyles from './styles';

export const MAX_ZOOM = 10;
export const INITIAL_ZOOM = 0;
export const MIN_ZOOM = -3;
const STEP_ZOOM = 1;
export const MAX_ROTATE = 360;
export const MIN_ROTATE = 0;
const STEP_ROTATE = 90;
const defaultGetImage = (item: any) => item;
const defaultCheckImage = (item: any) => Boolean(item);

interface IProps {
  pages: string[];
  initialPage?: number;
  showControls?: boolean;
  onClose?: () => void;
  additionalControls?: ReactNode;
  getImage?: (item: any) => string;
  checkImage?: (item: any) => boolean;
  className?: string;
}

// TODO: блокировать кнопки зума, поворота и т.д. при загрузке документа - не крит

const ImagesCarousel: React.FC<IProps> = (props) => {
  const {
    pages,
    initialPage = 0,
    additionalControls,
    showControls,
    onClose,
    getImage = defaultGetImage,
    checkImage = defaultCheckImage,
    className,
  } = props;
  const styles = useStyles();

  const [fileIndex, setFileIndex] = useState(initialPage);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [rotate, setRotate] = useState(MIN_ROTATE);

  const handleRotate = () => {
    setRotate((prevRotate) => (prevRotate === MAX_ROTATE ? STEP_ROTATE : prevRotate + STEP_ROTATE));
  };

  const handleIncrementZoom = () => {
    if (zoom < MAX_ZOOM) {
      setZoom((value) => value + STEP_ZOOM);
    }
  };

  const handleDecrementZoom = () => {
    if (zoom > MIN_ZOOM) {
      setZoom((value) => value - STEP_ZOOM);
    }
  };

  return (
    <Grid
      container
      className={cn('SourceFileList', className)}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="nowrap"
      sx={styles.list}
    >
      <Carousel
        pages={pages}
        fileIndex={fileIndex}
        setFileIndex={setFileIndex}
        rotate={rotate}
        zoom={zoom}
        getImage={getImage}
        checkImage={checkImage}
        setZoom={setZoom}
        showControls={showControls}
      />
      {onClose && (
        <Button
          sx={styles.closeButton}
          variant="text"
          onClick={onClose}
          icon={
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <X />
            </SvgIcon>
          }
        />
      )}
      {showControls && (
        <Controls
          pages={pages}
          fileIndex={fileIndex}
          handleRotate={handleRotate}
          handleIncrementZoom={handleIncrementZoom}
          handleDecrementZoom={handleDecrementZoom}
          additionalControls={additionalControls}
        />
      )}
    </Grid>
  );
};

export default ImagesCarousel;
