import { chatTokenName, getToken } from 'src/api/token-store';
import { IRouteParams } from 'src/app/consts/routes';
import WebSocketClient from 'src/components/MattermostSocket';
import { getChatsPostsList, getChatsUsersList } from 'src/pages/chats/ChatsChannel/actions';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

enum EventEnum {
  POSTED = 'posted',
}

interface SocketEvent {
  event: EventEnum;
  broadcast: { channel_id: string };
}

const SocketLayout: FC = (props) => {
  const { chatId } = useParams<IRouteParams>();

  const dispatch = useDispatch();

  useEffect(() => {
    const fn = async () => {
      try {
        await WebSocketClient.initialize(getToken(chatTokenName)!, {});
      } finally {
        WebSocketClient.setEventCallback(eventHandler);
      }
    };
    fn();
  }, []);

  const onPosted = (e: SocketEvent) => {
    if (e.broadcast.channel_id === chatId) {
      dispatch(getChatsPostsList({ chatId }));
      dispatch(getChatsUsersList({ chatId }));
    }
  };

  const eventHandler = (e: SocketEvent) => {
    switch (e.event) {
      case EventEnum.POSTED:
        return onPosted(e);
      default:
        return null;
    }
  };

  return props.children as JSX.Element;
};

export default SocketLayout;
