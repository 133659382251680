import { useForceUpdate, useInteraction } from '@core-ui/hooks';
import Loader from '@core-ui/loader';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import State from 'src/app/types/state';
import { useAppSelector } from 'src/hooks/useAppSelector';
import isEqual from 'lodash/isEqual';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ActionFunctionAny } from 'redux-actions';
import { Selector } from 'reselect';

const OFFSET = 32;

interface IProps {
  getChunk: ActionFunctionAny<any>;
  selector: Selector<State, { isAllDataReceived: boolean; isChunkLoading: boolean }>;
}

const ChunkLoader: FC<IProps> = (props) => {
  const { getChunk, selector } = props;

  const forceUpdate = useForceUpdate();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const dispatch = useDispatch();

  const { isAllDataReceived, isChunkLoading } = useAppSelector(selector, isEqual);

  const getMoreData = useCallback(() => {
    if (!isAllDataReceived && !isChunkLoading) {
      dispatch(getChunk(null));
    }
  }, [isAllDataReceived, isChunkLoading]);

  useInteraction({
    getMoreData,
    ref,
    allDataReceived: isAllDataReceived,
    offset: OFFSET,
  });

  useEffect(() => {
    if (!isChunkLoading && !isAllDataReceived) {
      forceUpdate();
    }
  }, [isChunkLoading, isAllDataReceived]);

  return (
    <Grid ref={ref} container minHeight="1px" justifyContent="center" alignItems="center">
      {isChunkLoading && <Loader size={100} padding={5} mode="circle" />}
    </Grid>
  );
};

export default ChunkLoader;
