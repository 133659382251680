/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum VariabilityTypeEnum {
  FIXED = 'fixed',
  VARIABLE = 'variable',
}
