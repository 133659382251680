import { Action, handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface INotificationState extends actions.IShowNotification {
  show: boolean;
}

const defaultState = Immutable<INotificationState>({
  show: false,
  variant: 'error',
  autoHideDuration: 5000,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.showNotification.toString()]: (_, { payload }: Action<actions.IShowNotification>) => {
      return defaultState.merge({
        ...payload,
        show: true,
      });
    },
    [actions.closeNotification.toString()]: (_, { payload }: Action<actions.ICloseNotification>) => {
      return defaultState.merge({
        variant: payload.variant,
        show: false,
      });
    },
  },
  defaultState
);
