import { ANALYTICS_INNER_TABS } from 'src/pages/analytics/AnalyticsInner/consts';
import { ANALYTICS_TABS } from 'src/pages/analytics/consts';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { DETAIL_REQUEST_TABS } from 'src/pages/backoffice/Requests/SingleRequest/types';
import { SETTINGS_TABS } from 'src/pages/settings/consts';

export enum ROUTES {
  MOBILE = 'mobile',
  LOGIN = 'login',
  BACKOFFICE = 'backoffice',
  FINANCES = 'finances',
  ANALYTICS = 'analytics',
  DOCUMENTS = 'documents',
  CHATS = 'chats',
  SETTINGS = 'settings',
  FAQ = 'faq',

  // deprecated, was used for old single request page
  PR = 'PR',
}

export interface IRouteParams {
  boatId: string;
  requestId: string;
  route: ROUTES;
  tabId: DETAIL_REQUEST_TABS;
  chatId: string;
  backofficeTab: BACKOFFICE_TABS;
  settings_tab: SETTINGS_TABS;
  analyticsTab: ANALYTICS_TABS;
  analyticsInnerTab: ANALYTICS_INNER_TABS;
}
