enum BACKEND_ROUTES {
  GET_CURRENCIES = 'currencies',
  GET_BOATS = 'boats',
  GET_DEPARTMENTS = 'departments',
  GET_EMPLOYEES = 'employees',
  GET_COST_CENTERS = 'cost_centers',
  GET_COST_ARTICLES = 'cost_articles',
  GET_CLIENT_COST_ARTICLES = 'client_cost_articles',
  GET_VENDORS = 'vendors',
  GET_REQUESTS = 'requests',
}

export default BACKEND_ROUTES;
