/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UploadingSourceEnum {
  INBOX = 'inbox',
  REQUEST = 'request',
  WALLET = 'wallet',
  CONTRACT = 'contract',
}
