import Autocomplete from '@core-ui/autocomplete';
import { COLOURS } from '@core-ui/styles';
import SvgIcon from '@mui/material/SvgIcon';
import { Boat } from '@phosphor-icons/react';
import cls from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { updateSelectedBoat } from '../../actions';
import selector from './selector';
import useStyles, { useSXStyles } from './styles';

interface IProps {}

const BoatSelection: React.FC<IProps> = () => {
  const classes = useStyles();
  const styles = useSXStyles();
  const dispatch = useDispatch();
  const { boatId, boatsOptions } = useAppSelector(selector);

  const onChange = (nextBoatId: number) => {
    dispatch(updateSelectedBoat({ boatId: nextBoatId }));
  };

  return (
    <div className={cls(classes.body, classes.aircraftSelection)}>
      <div className={classes.selection}>
        <SvgIcon htmlColor={COLOURS.WHITE}>
          <Boat weight="fill" />
        </SvgIcon>

        <Autocomplete
          size="small"
          value={boatId}
          options={boatsOptions}
          onChange={onChange}
          sx={styles.autocomplete}
        />
      </div>
    </div>
  );
};

export default BoatSelection;
