/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderWalletsFields {
  AMOUNT = 'amount',
  NAME = 'name',
}
