import { DocIconOcean, PdfIconOcean, UnknownUploadIconOcean, XlsIconOcean, ZipIconOcean } from '@core-ui/icons';
import { Image } from 'mui-image';

const getFileIconLabel = (file: File) => {
  switch (file.type) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
      return (
        <Image
          src={URL.createObjectURL(file)}
          height="100%"
          width="100%"
          fit="fill"
          // alt={file.name}
          // style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          style={{ borderRadius: '4px' }}
        />
      );
    case 'application/pdf':
      return <PdfIconOcean />;
    case 'application/zip':
      return <ZipIconOcean />;
    case 'application/xls':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
      return <XlsIconOcean />;
    case 'application/doc':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <DocIconOcean />;
    default:
      return <UnknownUploadIconOcean />;
  }
};

interface IProps {
  file: File;
}

const FileIcon: React.FC<IProps> = (props) => {
  const { file } = props;

  return getFileIconLabel(file);
};

export default FileIcon;
