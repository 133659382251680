// @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700&display=swap&subset=cyrillic');
const urlOne =
  // eslint-disable-next-line max-len
  "data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.875 10.875H13.875' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.875 7.875V13.875' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.4443 16.4437L21.0006 21' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E";

const urlTwo =
  // eslint-disable-next-line max-len
  "data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.875 10.875H13.875' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.4443 16.4437L21.0006 21' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E";

export default {
  styleOverrides: {
    html: {
      fontSize: 14,
    },

    body: {
      margin: 0,
      padding: 0,
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      fontFamily: 'Inter, sans-serif',
    },

    ' textarea, select, input,button': {
      outline: 'none',
    },
    a: {
      textDecoration: 'none',
    },
    // textarea: {
    //   fontFamily: 'Inter',
    //   fontSize: 14,
    //   lineHeight: 20,
    //   border: 'none',
    //   resize: 'none',
    //   padding: 0,
    //   width: '100%',
    //   '::placeholder': {
    //     fontSize: 12,
    //     lineHeight: 18,
    //   },
    //   ':disabled': {
    //     backgroundColor: 'inherit',
    //   },
    //   MsOverflowStyle: 'none',
    //   scrollbarWidth: 'none',
    //   overflowY: 'scroll',
    //   '::-webkit-scrollbar': {
    //     display: 'none',
    //   },
    // },

    '.ol-zoom-readonly': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
      position: 'absolute',
      bottom: 30,
      right: 20,
    },

    '.ol-zoom-in-readonly': {
      background: '#348AEB',
      borderRadius: 4,
      width: 44,
      height: 44,
      border: 'none',
      backgroundImage: `url("${urlOne}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },

    '.ol-zoom-out-readonly': {
      background: '#348AEB',
      borderRadius: 4,
      width: 44,
      height: 44,
      border: 'none',
      backgroundImage: `url("${urlTwo}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  },
};
