/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DocTypeLite {
  QUOTE = 'quote',
  INVOICE = 'invoice',
  RECEIPT = 'receipt',
}
