/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum myocean__routes__inbox__inbox_list__OrderFields {
  DOC_NAME = 'doc_name',
  VENDOR_NAME = 'vendor_name',
  CREATED_AT = 'created_at',
  DOC_STATUS = 'doc_status',
  UPLOADED_BY_ID = 'uploaded_by_id',
  FILENAME = 'filename',
}
