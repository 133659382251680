/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FileReasonOfReturn {
  LOW_QUALITY = 'low_quality',
  DUPLICATE = 'duplicate',
  NO_DATA_INPUT = 'no_data_input',
}
