import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { ArrowClockwise, DownloadSimple, MagnifyingGlassMinus, MagnifyingGlassPlus } from '@phosphor-icons/react';
import React, { ReactNode } from 'react';
import useStyles from '../styles';

interface IProps {
  pages: string[];
  fileIndex: number;
  handleRotate: () => void;
  handleIncrementZoom: () => void;
  handleDecrementZoom: () => void;
  additionalControls?: ReactNode;
}

const Controls: React.FC<IProps> = (props) => {
  const { pages, fileIndex, handleRotate, handleDecrementZoom, handleIncrementZoom, additionalControls } = props;
  const styles = useStyles();

  return (
    <Grid container sx={styles.controls} alignItems="center" justifyContent="space-between" flex="0">
      <Box>
        <Button
          onClick={handleRotate}
          icon={
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <ArrowClockwise />
            </SvgIcon>
          }
        />

        <Button
          onClick={handleDecrementZoom}
          icon={
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <MagnifyingGlassMinus />
            </SvgIcon>
          }
        />

        <Button
          onClick={handleIncrementZoom}
          icon={
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <MagnifyingGlassPlus />
            </SvgIcon>
          }
        />

        <Button
          href={pages[fileIndex]}
          target="_blank"
          icon={
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <DownloadSimple />
            </SvgIcon>
          }
        />
      </Box>

      <Box>{additionalControls}</Box>
    </Grid>
  );
};

export default Controls;
