export function isValidEmail(email: string) {
  if (email.length > 254) {
    return false;
  }

  return /^.+@.+\..+$/.test(email);
}

export function isValidPassword(password: string) {
  return password.trim().length >= 6;
}
