/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FileStatusEnum {
  BEING_DIGITIZED = 'being_digitized',
  DIGITIZED = 'digitized',
  RETURNED = 'returned',
  UNKNOWN = 'unknown',
}
