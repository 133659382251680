/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DocSortingColumns {
  NUMBER = 'number',
  DOCUMENT_TYPE_ID = 'document_type_id',
  STATUS = 'status',
  REQUEST_ID = 'request_id',
  VENDOR_ID = 'vendor_id',
  CREATED_AT = 'created_at',
  TOTAL_PRICE = 'total_price',
  UPLOADED_BY_ID = 'uploaded_by_id',
  WALLET_ID = 'wallet_id',
}
