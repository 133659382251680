import { createAction } from 'redux-actions';

export const reducerName = 'header';

export interface IUpdateSelectedBoat {
  boatId: number;
}

export const updateSelectedBoat = createAction<IUpdateSelectedBoat>(`${reducerName}.UPDATE_SELECTED_BOAT`);

export const logout = createAction(`${reducerName}.LOGOUT`);
