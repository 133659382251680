export enum StatusInfoColors {
  SUCCESS = 'success',
  INFO = 'info',
  DANGER = 'danger',
  PRIMARY = 'primary',
  PENDING = 'pending',
  DRAFT = 'draft',
  APPROVAL = 'approval',
  APPROVED = 'approved',
  DELIVERED = 'delivered',
  DONE = 'done',
  DIGITIZED = 'digitized',
}
