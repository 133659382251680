import { Nullable } from '@core-ui/types';
import { getCurrencyCodeById } from 'src/dictionary/selector';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { FC, useMemo } from 'react';

interface IProps {
  currencyId?: Nullable<string | number>;
}

/*
 * Deprecated component, use getCurrencyCodeById selector instead.
 * This component will be deleted during the Request section rework.
 */
const CurrencyCode: FC<IProps> = (props) => {
  const { currencyId } = props;

  const memoizedSelector = useMemo(() => getCurrencyCodeById, []);

  const currencyCode = useAppSelector((state) => memoizedSelector(state));

  if (!currencyId || !currencyCode) {
    return null;
  }

  return <>{currencyCode(Number(currencyId)).replace('NaN', '').trim()}</>;
};

export default CurrencyCode;
