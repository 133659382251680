import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import HideButton from 'src/components/HideButton';
import { Resizable } from 're-resizable';
import React, { CSSProperties, ElementType, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { SIDE_PANEL_DEFAULT_MAX_WIDTH, SIDE_PANEL_DEFAULT_MIN_WIDTH, SIDE_PANEL_DEFAULT_WIDTH } from './consts';

const disabledHandleStyles: CSSProperties = {
  cursor: 'default',
};

interface IComponentProps {
  children?: ReactNode | ReactNode[];
  defaultWidth?: number;
  minWidth?: number;
  maxWidth?: number;
  disableResize?: boolean;
}

const withHide = (WrappedComponent: ElementType, direction: 'left' | 'right', duration: number) => {
  return (props: IComponentProps) => {
    const {
      children,
      defaultWidth = SIDE_PANEL_DEFAULT_WIDTH,
      minWidth = SIDE_PANEL_DEFAULT_MIN_WIDTH,
      maxWidth = SIDE_PANEL_DEFAULT_MAX_WIDTH,
      disableResize = false,
      ...rest
    } = props;

    const panelRef = useRef<Nullable<HTMLDivElement>>(null);
    const [inProp, setInProp] = useState(localStorage.getItem(direction) === 'true');
    const [width, setWidth] = useState(inProp ? defaultWidth : 0);

    useEffect(() => {
      localStorage.setItem(direction, 'false');
    }, []);

    const toggleInProp = useCallback(() => {
      if (panelRef?.current?.offsetWidth) {
        setInProp(!inProp);
        setWidth(panelRef.current.offsetWidth);

        localStorage.setItem(direction, `${!inProp}`);
      }
    }, [inProp, panelRef?.current]);

    const propMargin = `margin${direction[0].toUpperCase()}${direction.slice(1)}`;

    const defaultStyles = {
      height: '100%',
      transition: `margin-${direction} ${duration}ms ease-in-out, border ${duration}ms ease-in-out`,
      [propMargin]: -4,
      borderRight:
        direction === 'left'
          ? disableResize
            ? `1px solid ${COLOURS.HUE_COCONUT.HUE_32}`
            : inProp
            ? '4px solid transparent'
            : `4px solid ${COLOURS.HUE_COCONUT.HUE_32}`
          : 'none',
      borderLeft:
        direction === 'right'
          ? disableResize
            ? `1px solid ${COLOURS.HUE_COCONUT.HUE_32}`
            : inProp
            ? '4px solid transparent'
            : `4px solid ${COLOURS.HUE_COCONUT.HUE_32}`
          : 'none',
    };

    const transitionStyles = {
      entering: { [propMargin]: `-${width}px` },
      entered: { [propMargin]: `-${width}px` },
      exiting: { [propMargin]: 0 },
      exited: { [propMargin]: 0 },
    };

    const handleStyles = disableResize
      ? direction === 'right'
        ? { left: disabledHandleStyles }
        : { right: disabledHandleStyles }
      : undefined;

    return (
      <Transition in={inProp} timeout={500}>
        {(state: 'entering' | 'entered' | 'exiting' | 'exited') => (
          // !inProp check is needed to disable resizing when the panel is hidden
          <Resizable
            enable={{ left: !inProp && direction === 'right', right: !inProp && direction === 'left' }}
            minWidth={minWidth ?? SIDE_PANEL_DEFAULT_MIN_WIDTH}
            maxWidth={maxWidth ?? SIDE_PANEL_DEFAULT_MAX_WIDTH}
            defaultSize={{ width: defaultWidth, height: '100%' }}
            minHeight="100%"
            handleStyles={handleStyles}
            style={{
              ...defaultStyles,
              ...transitionStyles[state],
            }}
            {...rest}
          >
            <WrappedComponent ref={panelRef} style={{ display: 'flex', flexDirection: 'column', flex: '1 1' }}>
              <HideButton isActive={inProp} direction={direction} onClick={toggleInProp} />

              {children}
            </WrappedComponent>
          </Resizable>
        )}
      </Transition>
    );
  };
};

export default withHide;
