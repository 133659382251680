/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderContractsFields {
  AMOUNT = 'amount',
  NAME = 'name',
  TAGS = 'tags',
  VENDOR_ID = 'vendor_id',
  EXPIRES_AT = 'expires_at',
}
