import { asMutable } from '@core-ui/immutable';
import { BREAKPOINTS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import { CLIENT_DEVICE_OS } from 'src/app/consts/app';
import { ICurrencyDict } from 'src/dictionary/reducer';
import { client } from 'src/sentry';
import { ValidationErrors } from 'final-form';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import { LegacyRef, MutableRefObject, RefCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export function getWindowBreakpoint(width: number): number {
  const breakpoints = Object.values(BREAKPOINTS).reverse(); // чтоб с самого крупного до самого мелкого
  const found = breakpoints.find((breakpoint) => width >= breakpoint) as number;

  return found || 0;
}

export const submitForm = (formId: string) => {
  // https://github.com/final-form/react-final-form/issues/878
  document.getElementById(formId)?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
};

export function setRequiredFields<T extends Record<string, any>>(
  values: T,
  errors: ValidationErrors,
  keys: Array<keyof T>
) {
  if (values && errors) {
    keys.forEach((key) => {
      const value = get(values, key);

      if (!value || (Array.isArray(value) && value.length === 0)) {
        set(errors, key, <FormattedMessage id="form.errors.required" />);
      }
    });
  }
}

export const getFirstLettersOfUser = (firstname?: string, lastname?: string) => {
  if (!firstname || !lastname) {
    return null;
  }

  return `${firstname[0].toUpperCase() || ''}${lastname[0].toUpperCase() || ''} `;
};

export const exhaustiveGuard = (value: never): never => {
  throw new Error(`ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(value)}`);
};

export function throwError(error: string) {
  throw new Error(error);
}

export const checkMobile = (): boolean => {
  return window.screen.width < BREAKPOINTS.SM;
};

export const getDeviceOS = (): CLIENT_DEVICE_OS => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return CLIENT_DEVICE_OS.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return CLIENT_DEVICE_OS.IOS;
  }

  return CLIENT_DEVICE_OS.UNKNOWN;
};

export const captureCurrency = (currencyId: Nullable<number>, currencyMap: Record<number, ICurrencyDict>) => {
  client.withScope(() => {
    client.setExtra('currencyMap', currencyMap);
    client.setExtra('currencyId', currencyId);
    client.captureMessage('Currency id does not exist', 'log');
  });
};

export const generateEmptyArray = (length: number): Array<number> => {
  return new Array(length).fill(null);
};
export const toArray = (value: any) => (Array.isArray(value) ? value : [value]);
export const toMutableArray = (value: any) => asMutable(toArray(value), { deep: true });

export function mergeRefs<T = any>(
  ...refs: Array<MutableRefObject<T> | LegacyRef<T> | undefined | null>
): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<Nullable<T>>).current = value;
      }
    });
  };
}

export const getRoundedNumber = (num: number, fractionDigits = 0): string => {
  const digitsLetters = ['K', 'M', 'B', 'T'];

  // сохраняем знак
  const sign = num >= 0 ? '' : '-';
  // берем модуль
  const absValue = Math.abs(num);
  // готовим перевернутое число (строку)
  const str = String(absValue).split('.')[0];
  const reversedStr = str.split('').reverse().join('');

  // находим разряды
  const digits = reversedStr.match(new RegExp('\\d{3}', 'g'));
  // количество разрядов
  const countDigits = digits?.length ?? 0;

  if (countDigits === 0) {
    return `${sign}${absValue}`;
  }

  // итоговое количество разрядов (чтобы не сокращать в 0, например, 123456 -> 0М)
  const finalCountDigits = countDigits * 3 === str.length ? countDigits - 1 : countDigits;
  // количество чисел в разрядах
  const countNumbers = finalCountDigits * 3;

  if (finalCountDigits === 0 && fractionDigits === 0) {
    return `${sign}${absValue}`;
  }

  // получаем числа, невошедшие в группировку по разрядам
  const restNumbers = str.slice(0, -countNumbers);
  // количество чисел
  const countRestNumber = restNumbers.length;
  // получаем числа после запятой
  const fractionNumbers = str.slice(countRestNumber, countRestNumber + fractionDigits);
  // буква для сокращения
  const letter = digitsLetters[finalCountDigits - 1];

  return fractionDigits === 0 ? `${sign}${restNumbers}${letter}` : `${sign}${restNumbers}.${fractionNumbers}${letter}`;
};
