import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import { Image } from 'mui-image';
import { useEffect, useState } from 'react';

interface IProps {
  selectedIdx?: number;
  images: string[];
  getCurrentIndex?: (idx: number) => void;
}

const buttonStyles: SxProps<Theme> = {
  width: '44px',
  height: '44px',
  backgroundColor: '#000',
  borderRadius: '4px',
};

// TODO: эта карусель используется только в чатах до допиливания которых мы ещё не добрались, поэтому пока её оставляем.
// Но когда дойдём до чатов нужно будет её заменить на ImagesCarousel
const ChatImagesCarousel: React.FC<IProps> = (props) => {
  const { images, selectedIdx = 0, getCurrentIndex } = props;
  const [index, setIndex] = useState(selectedIdx);

  const increase = () => {
    setIndex(index === images.length - 1 ? 0 : index + 1);
  };
  const decrease = () => {
    setIndex(index === 0 ? images.length - 1 : index - 1);
  };

  useEffect(() => getCurrentIndex && getCurrentIndex(index), [index]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        padding: '70px 12px',
        borderRadius: '4px',
        height: '100%',
        width: '100%',
      }}
    >
      <Grid container wrap="nowrap" columnGap={2} height="100%" alignItems="center">
        <IconButton onClick={decrease} sx={buttonStyles}>
          <KeyboardArrowLeftIcon sx={{ color: '#fff' }} />
        </IconButton>

        <Image
          // src={URL.createObjectURL(file)}
          src={images[index]}
          height="100%"
          width="100%"
          fit="scale-down"
          showLoading
          style={{ borderRadius: '4px' }}
        />

        <IconButton onClick={increase} sx={buttonStyles}>
          <KeyboardArrowRight sx={{ color: '#fff' }} />
        </IconButton>
      </Grid>
    </Paper>
  );
};

export default ChatImagesCarousel;
