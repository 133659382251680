/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DictsEnum {
  APPROVE_STATUS = 'approve_status',
  STATUS = 'status',
  COMPANY_TYPE = 'company_type',
  DOCUMENT_TYPE = 'document_type',
  EMPLOYEE_TYPE = 'employee_type',
  REQUEST_TYPE = 'request_type',
  CASH_PAYMENT_TYPE = 'cash_payment_type',
  QTY_TYPE = 'qty_type',
  VAT_TYPE = 'vat_type',
}
