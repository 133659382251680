/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CashPaymentTypeEnum {
  CASH = 'cash',
  CREDIT_CARD = 'credit_card',
  WIRE_TRANSFER = 'wire_transfer',
}
