import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { TRANSITION_TIMING_FUNCTION } from 'src/app/consts/app';

export default (): Record<string, SxProps<Theme>> => ({
  defaultStyles: {
    backgroundColor: `${COLOURS.WHITE} !important`,
    borderRadius: '16px !important',
    borderColor: `${COLOURS.HUE_COCONUT.HUE_32} !important`,
    color: `${COLOURS.HUE_COCONUT.HUE_172} !important`,
    transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,

    '&:hover': {
      borderColor: `${COLOURS.HUE_COCONUT.HUE_100} !important`,
      color: `${COLOURS.HUE_COCONUT.HUE_195} !important`,
    },
  },
});
