import { COLOURS } from '@core-ui/styles';
import makeStyles from '@mui/styles/makeStyles';

// TODO: избавиться от makeStyles (без него сейчас не работает)
export default makeStyles({
  root: {
    position: 'absolute',
    cursor: 'pointer',
    height: 30,
    width: 30,
    padding: 0,
    margin: 0,
    top: 4,
    background: COLOURS.WHITE,
    border: `1px solid ${COLOURS.HUE_COCONUT.HUE_32}`,
    borderRadius: '0 50% 50% 0',
    zIndex: 2,
    transition: 'all 300ms ease-in-out',

    '& svg': {
      color: COLOURS.HUE_COCONUT.HUE_195,
      transition: 'all 300ms ease-in-out',
    },

    '&:hover': {
      borderColor: `${COLOURS.HUE_COCONUT.HUE_100}`,
    },
  },

  left: {
    left: 0,
  },

  right: {
    right: 0,
    transform: 'rotate(180deg)',
  },

  leftActive: {
    left: '100%',

    '& svg': {
      transform: 'rotate(180deg)',
    },
  },

  rightActive: {
    right: '100%',

    '& svg': {
      transform: 'rotate(-180deg)',
    },
  },
});
