/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FileStorageExpiringEnum {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  EXPIRE_SOON = 'expire_soon',
}
