/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RequestStatusEnum {
  DRAFT = 'draft',
  SUBSTANCE = 'substance',
  QUOTE = 'quote',
  INVOICE = 'invoice',
  PAYMENT = 'payment',
  DECLINED = 'declined',
}
