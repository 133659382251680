import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'src/hooks/useAppSelector';
import React from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeNotification } from './actions';
import selector from './selector';

const portalId = 'notification';

const TransitionDown: React.FC<SlideProps> = (props) => <Slide {...props} direction="down" />;

const Notification: React.FC = () => {
  const { title, titleId, message, messageId, variant, show, autoHideDuration } = useAppSelector(selector);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleClose = () => {
    dispatch(closeNotification({ variant }));
  };

  let portal = document.getElementById(portalId);

  if (!portal) {
    const newPortal = document.createElement('div');
    newPortal.id = portalId;
    portal = newPortal;
    document.body.appendChild(newPortal);
  }

  const formattedTitle = titleId ? intl.formatMessage({ id: titleId }) : title;
  let formattedMessage;

  if (messageId) {
    formattedMessage = Array.isArray(messageId)
      ? messageId.map((id) => intl.formatMessage({ id }))
      : intl.formatMessage({ id: messageId as string });
  } else {
    formattedMessage = message;
  }

  return ReactDOM.createPortal(
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionComponent={TransitionDown}
      disableWindowBlurListener
      ClickAwayListenerProps={{
        onClickAway: () => {
          // пустая функция, чтобы компонент не скрывался при клике за его пределы
        },
      }}
    >
      <Alert variant="filled" severity={variant} icon={false} onClose={handleClose}>
        {formattedTitle && <AlertTitle>{formattedTitle}</AlertTitle>}
        {(Array.isArray(formattedMessage) ? formattedMessage : [formattedMessage]).map((item, index) => (
          <Typography key={index} variant="body1">
            {item}
          </Typography>
        ))}
      </Alert>
    </Snackbar>,
    portal
  );
};

export default Notification;
