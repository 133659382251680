/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type QuotaToInvoiceInputSchema = {
  document_id: number;
  invoice_type: QuotaToInvoiceInputSchema.invoice_type;
};

export namespace QuotaToInvoiceInputSchema {
  export enum invoice_type {
    PRE_PAYMENT_INVOICE = 'pre_payment_invoice',
    POST_PAYMENT_INVOICE = 'post_payment_invoice',
  }
}
